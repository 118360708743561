<script setup
        lang="ts">
import { useEvent, usePageLeave, usePageView }          from '@reshepe/hooks';
import { useThemeStore }                                from '@reshepe/stores';
import { useNProgress }                                 from '@vueuse/integrations/useNProgress';
import { inject, onMounted, ref, defineAsyncComponent } from 'vue';
import { useRouter }                                    from 'vue-router';
import { WebVitals }                                    from '@reshepe-web-vitals/vue';
import type { PostHog }                                 from 'posthog-js';

const GdprNotice = defineAsyncComponent(() => import('@reshepe/ui/gdpr.vue'));

useThemeStore();

const { isLoading } = useNProgress();
const router = useRouter();
const posthog = inject<PostHog | undefined>('posthog');

router.onError((error, to) => {
    if (
        error.message.includes('Failed to fetch dynamically imported module') ||
        error.message.includes('Importing a module script failed')
    ) {
        if (!to?.fullPath) {
            window.location.reload();
        } else {
            window.location.href = to.fullPath;
        }
    }
});

router.beforeEach(async (to, from, next) => {
    if (to.path !== from.path) {
        isLoading.value = true;
    }

    next();
});

router.afterEach((to, from, failure) => {
    if (!failure) {
        usePageLeave(from.path);
        usePageView(to.path);
    }
});

window.addEventListener('beforeunload', () => {
    posthog?.capture('$pageleave', {
        path: router.currentRoute.value.path,
    });
});

window.addEventListener('vite:preloadError', (e: Event): void => {
    e.preventDefault();

    useEvent('vite:preloadError');

    window.location.reload();
});

const loaded = ref<boolean>(false);
const dev = ref<boolean>(import.meta.env.MODE === 'development');
const preview = ref<boolean>(import.meta.env.VITE_VERCEL_ENV === 'preview');

const console_messages = (): void => {
    console.info(
        '01110100 01101000 01101001 01110011 00100000 01100111 01110101 01111001 00100000 01100110 01110101 01100011 01101011 01110011',
    );

    console.info(`%c
⠄⠄⠄⠄⠄⠄⠄⢀⣠⣶⣾⣿⣶⣦⣤⣀⠄⢀⣀⣤⣤⣤⣤⣄⠄⠄⠄⠄⠄⠄
⠄⠄⠄⠄⠄⢀⣴⣿⣿⣿⡿⠿⠿⠿⠿⢿⣷⡹⣿⣿⣿⣿⣿⣿⣷⠄⠄⠄⠄⠄
⠄⠄⠄⠄⠄⣾⣿⣿⣿⣯⣵⣾⣿⣿⡶⠦⠭⢁⠩⢭⣭⣵⣶⣶⡬⣄⣀⡀⠄⠄
⠄⠄⠄⡀⠘⠻⣿⣿⣿⣿⡿⠟⠩⠶⠚⠻⠟⠳⢶⣮⢫⣥⠶⠒⠒⠒⠒⠆⠐⠒
⠄⢠⣾⢇⣿⣿⣶⣦⢠⠰⡕⢤⠆⠄⠰⢠⢠⠄⠰⢠⠠⠄⡀⠄⢊⢯⠄⡅⠂⠄
⢠⣿⣿⣿⣿⣿⣿⣿⣏⠘⢼⠬⠆⠄⢘⠨⢐⠄⢘⠈⣼⡄⠄⠄⡢⡲⠄⠂⠠⠄
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣷⣥⣀⡁⠄⠘⠘⠘⢀⣠⣾⣿⢿⣦⣁⠙⠃⠄⠃⠐⣀
⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣋⣵⣾⣿⣿⣿⣿⣦⣀⣶⣾⣿⣿⡉⠉⠉
⣿⣿⣿⣿⣿⣿⣿⠟⣫⣥⣬⣭⣛⠿⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⡆⠄
⣿⣿⣿⣿⣿⣿⣿⠸⣿⣏⣙⠿⣿⣿⣶⣦⣍⣙⠿⠿⠿⠿⠿⠿⠿⠿⣛⣩⣶⠄
⣛⣛⣛⠿⠿⣿⣿⣿⣮⣙⠿⢿⣶⣶⣭⣭⣛⣛⣛⣛⠛⠛⠻⣛⣛⣛⣛⣋⠁⢀
⣿⣿⣿⣿⣿⣶⣬⢙⡻⠿⠿⣷⣤⣝⣛⣛⣛⣛⣛⣛⣛⣛⠛⠛⣛⣛⠛⣡⣴⣿
⣛⣛⠛⠛⠛⣛⡑⡿⢻⢻⠲⢆⢹⣿⣿⣿⣿⣿⣿⠿⠿⠟⡴⢻⢋⠻⣟⠈⠿⠿
⣿⡿⡿⣿⢷⢤⠄⡔⡘⣃⢃⢰⡦⡤⡤⢤⢤⢤⠒⠞⠳⢸⠃⡆⢸⠄⠟⠸⠛⢿
⡟⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠁⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⠄⢸
`, 'color: green;');

    if (preview.value) {
        console.log(import.meta.env);
    }
};

onMounted(async () => {
    document.getElementById('app')?.classList.remove('hidden');
    document.getElementById('app')?.classList.add('block');
    document.getElementById('loader')?.remove();

    if (!dev.value) {
        console_messages();
    }

    loaded.value = true;
});
</script>

<template>
    <router-view v-slot="{ Component }">
        <component :is="Component" />
    </router-view>
    <GdprNotice v-if="loaded" />
    <WebVitals api-key="pk_beeea90a-3235-4756-9385-357bbe7b5501"
               :development="dev || preview"
               report-soft-navigation />
</template>
